<template>
  <base-section
    id="nitrile-gloves"
  >
    <v-container class="fill-height px-4 py-0">
      <v-responsive
        class="d-flex align-center mx-auto"
        height="100%"
        width="100%"
      >
      <base-section-heading
        title="nitrile gloves"
      />
        <v-row>
          <v-col
            cols="12"
            sm="3"
            class="text-center"
          >
            <template
              v-for="(card, i) in cards"
            >
              <base-avatar-card-portfolio
                :key="i"
                align="center"
                v-bind="card"
                @click.native="fnChangeTab(card)"
              />
              <v-divider
                v-if="i + 1 !== cards.length"
                :key="`divider-${i}`"
                class="my-8"
              />
            </template>
          </v-col>

          <v-col
            cols="0"
            sm="1"
            class="text-center px-0 mx-0"
          >
            <v-responsive
              height="calc(100% - 16px)"
              class="mx-0 px-0"
            >
              <v-divider
                vertical
                dark
                class="mx-0 px-0"
              />
            </v-responsive>
          </v-col>

          <v-col
            cols="12"
            sm="8"
          >
            <base-subheading
              size="text-h5"
              :title="cards[currentTab].title"
              space="0"
              mobileSize="text-h6"
              class="text-uppercase"
            />

            <base-title
              class="primary--text"
              :title="cards[currentTab].location"
              tag="div"
            />
            <v-img
              :src="cards[currentTab].photo"
              height="300"
            />
            <br>
            <base-body
              :html="cards[currentTab].description"
            />
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <div class="text-center">
                  <v-btn
                    class="font-weight-bold fourth"
                    text
                    @click=fnOpenReadMore
                    color="white"
                    min-width="180"
                  >
                    Read More
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div class="text-center">
                  <v-btn
                    class="font-weight-bold third"
                    text
                    @click=fnGoToContact
                    color="white"
                    min-width="180"
                  >
                    Request Sample
                  </v-btn>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <div class="text-center">
                  <v-btn
                    class="font-weight-bold primary"
                    text
                    @click=fnOpenBrochure(cards[currentTab].no)
                    min-width="180"
                  >
                    Download Info
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="readMoreOpened"
              class="justify-center"
            >
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="item in cards[currentTab].specs"
                      :key="item.name"
                    >
                      <td>{{ item.name }}</td>
                      <td
                        class="py-8"
                      >
                        <v-row>
                          {{ item.desc }}
                        </v-row>
                        <v-row>
                          <v-img
                            :src="item.logo1"
                            contain
                            max-width="60"
                          />
                          <v-img
                            :src="item.logo2"
                            contain
                            max-width="60"
                          />
                          <v-img
                            :src="item.logo3"
                            contain
                            max-width="60"
                          />
                        </v-row>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-col>
        </v-row>
      </v-responsive>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNitrileGloves',
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    data: () => ({
      currentTab: 0,
      readMoreOpened: false,
      cards: [
        {
          no: 0,
          title: 'Examination Gloves',
          description: 'Nitrile powder-free examination gloves for superior personal barrier protection <br><ul><li>Beaded cuff design and textured fingertips for confident, secure grip.</li><li>Soft, durable nitrile material provides excellent protection and flexibility.</li><li>Tested and comply according to ASTM D6319 and EN455 standards.</li></ul>',
          location: 'Examination Gloves',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          photo: require('@/assets/product_1.jpg'),
          chosen: true,
          specs: [
            {
              name: 'Product Type',
              desc: 'Powder Free Nitrile Examination Gloves (Non Sterile)',
            },
            {
              name: 'Available Color',
              desc: 'Blue / Black / White and various colors according to customer requirement.',
            },
            {
              name: 'Available Weight',
              desc: '3.2g / 3.5g / 4.5g / 5.0g / 6.8g and any other weight according to customer requirement.',
            },
            {
              name: 'Raw Material',
              desc: 'Nitrile Butadiene Latex',
            },
            {
              name: 'Length',
              desc: 'Min.240mm',
            },
            {
              name: 'Quality',
              desc: 'AQL 1.5',
            },
            {
              name: 'Features',
              desc: 'Ambidextrous, Finger textured, Beaded Cuff',
            },
            {
              name: 'Finishing',
              desc: 'Online Chlorinated',
            },
            {
              name: 'Shelf Life',
              desc: '5 years',
            },
            {
              name: 'Storage',
              desc: 'Store in a cool and dry place. Keep away from sunlight.',
            },
            {
              name: 'Nitrile Examination Gloves Standard',
              desc: 'ASTM D6319, EN 455, AS/NZS 4011',
              logo1: require('@/assets/ASTM.png'),
              logo2: require('@/assets/CE.png'),
            },
          ],
        },
        {
          no: 1,
          title: 'Food Contact Gloves',
          description: 'Tested and approved according to 21 CFR 177.2600, Safe for Food Contact <br><ul><li>Textured Nitrile for enhance tactility.</li><li>Examination grade and food contact approved.</li><li>Consistent performance.</li></ul>',
          location: 'Food Contact Gloves',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          photo: require('@/assets/product_2.jpg'),
          chosen: false,
          specs: [
            {
              name: 'Product Type',
              desc: 'Food Contact Gloves',
            },
            {
              name: 'Available Color',
              desc: 'Blue / Black and various colors according to customer requirement.',
            },
            {
              name: 'Available Weight',
              desc: '3.2g / 3.5g / 5.0g and any other weight according to customer requirement.',
            },
            {
              name: 'Raw Material',
              desc: 'Nitrile Butadiene Latex',
            },
            {
              name: 'Length',
              desc: 'Min.240mm',
            },
            {
              name: 'Quality',
              desc: 'AQL 1.5',
            },
            {
              name: 'Features',
              desc: 'Ambidextrous, Finger textured, Beaded Cuff',
            },
            {
              name: 'Finishing',
              desc: 'Online Chlorinated',
            },
            {
              name: 'Shelf Life',
              desc: '5 years',
            },
            {
              name: 'Storage',
              desc: 'Store in a cool and dry place. Keep away from sunlight.',
            },
            {
              name: 'Nitrile Examination Gloves Standard',
              desc: 'ASTM D6319, EN 455',
              logo1: require('@/assets/ASTM.png'),
              logo2: require('@/assets/CE.png'),
            },
            {
              name: 'Food Contact',
              desc: 'USFDA 21 CFR 117.2600, Korea MFDS Food Sanitation Act, China GB 4806.11-2016',
              logo1: require('@/assets/Food Contact mark.png'),
              logo2: require('@/assets/Korea Food Sanitation Act.png'),
            },
          ],
        },
        {
          no: 2,
          title: 'Chemotherapy and Fentanyl Tested Gloves',
          description: 'Nitrile, powder-free examination gloves with chemotherapy drugs permeation test<br><ul><li>Extra protection for chemotherapy drugs handling or chemotherapy waste disposal.</li><li>Achieve high resistance to permeation by commonly used chemotherapy drugs.</li><li> Permeation tested as per ASTM D6978-05 by accredited lab.</li></ul>',
          location: 'Chemotherapy and Fentanyl Tested Gloves',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          photo: require('@/assets/product_3.jpg'),
          chosen: false,
          specs: [
            {
              name: 'Product Type',
              desc: 'Chemotherapy Gloves',
            },
            {
              name: 'Available Color',
              desc: 'Blue and various colors according to customer requirement.',
            },
            {
              name: 'Available Weight',
              desc: '3.5g / 5.0g and any other weight according to customer requirement.',
            },
            {
              name: 'Raw Material',
              desc: 'Nitrile Butadiene Latex',
            },
            {
              name: 'Length',
              desc: 'Min.240mm',
            },
            {
              name: 'Quality',
              desc: 'AQL 1.5',
            },
            {
              name: 'Features',
              desc: 'Ambidextrous, Finger textured, Beaded Cuff',
            },
            {
              name: 'Finishing',
              desc: 'Online Chlorinated',
            },
            {
              name: 'Shelf Life',
              desc: '5 years',
            },
            {
              name: 'Storage',
              desc: 'Store in a cool and dry place. Keep away from sunlight.',
            },
            {
              name: 'Nitrile Examination Gloves Standard',
              desc: 'ASTM D6319, EN 455',
              logo1: require('@/assets/ASTM.png'),
              logo2: require('@/assets/CE.png'),
            },
            {
              name: 'Chemotherapy Drugs and Fentanyl Tested',
              desc: 'ASTM D6978',
              logo1: require('@/assets/ASTM.png'),
            },
          ],
        },
        {
          no: 3,
          title: 'PPE (EU) 2016/ 425 with CE Category III gloves',
          description: 'Chemical resistant gloves <br><ul><li>Compliance to EN420 and EN374 standards.</li><li>Tested against 6 standard defined chemicals with breakthrough time at least 30 minutes.</li><li> Provide highest level of protection against specific hazardous chemical handling and micro-organisms.</li></ul>',
          location: 'PPE (EU) 2016/ 425 with CE Category III gloves',
          duration: 'November 2019',
          value: 'Project value: RM 6,400,000',
          photo: require('@/assets/product_4.jpg'),
          chosen: false,
          specs: [
            {
              name: 'Product Type',
              desc: 'PPE with CE Category III Gloves',
            },
            {
              name: 'Available Color',
              desc: 'Blue / Black / White and various colors according to customer requirement.',
            },
            {
              name: 'Available Weight',
              desc: '3.5g / 5.0g and any other weight according to customer requirement.',
            },
            {
              name: 'Raw Material',
              desc: 'Nitrile Butadiene Latex',
            },
            {
              name: 'Length',
              desc: 'Min.240mm',
            },
            {
              name: 'Quality',
              desc: 'AQL 1.5',
            },
            {
              name: 'Features',
              desc: 'Ambidextrous, Finger textured, Beaded Cuff',
            },
            {
              name: 'Finishing',
              desc: 'Online Chlorinated',
            },
            {
              name: 'Shelf Life',
              desc: '5 years',
            },
            {
              name: 'Storage',
              desc: 'Store in a cool and dry place. Keep away from sunlight.',
            },
            {
              name: 'Nitrile Examination Gloves Standard',
              desc: 'ASTM D6319, EN 455, EN 420',
              logo1: require('@/assets/ASTM.png'),
              logo2: require('@/assets/CE.png'),
              logo3: require('@/assets/EN-420.png'),
            },
            {
              name: 'Protection against Microorganisms and Chemicals',
              desc: 'EN ISO 374, EN ISO 374-5',
              logo1: require('@/assets/EN ISO 374.png'),
              logo2: require('@/assets/EN ISO 374-5 Virus.png'),
            },
          ],
        },
      ],
    }),
    methods: {
      fnOpenReadMore () {
        this.readMoreOpened = true
      },
      fnChangeTab (card) {
        this.currentTab = card.no
        this.cards[0].chosen = false
        this.cards[1].chosen = false
        this.cards[2].chosen = false
        this.cards[3].chosen = false
        card.chosen = true
        this.readMoreOpened = false
      },
      fnGoToContact () {
        this.$router.push('/contact')
      },
      fnOpenBrochure (no) {
        if (no === 0) {
          window.open('https://drive.google.com/file/d/1_XTC2Lf57JA9jMr50qwUFrATwozXa-Y0/view?usp=drive_link', '_blank')
        } else if (no === 1) {
          window.open('https://drive.google.com/file/d/1Bqk4ROQJtkIz_Uf5bisnNjbBPrmWeioY/view?usp=drive_link', '_blank')
        } else if (no === 2) {
          window.open('https://drive.google.com/file/d/1B7qZZ1mLTi3uPRBMtqbICTnq4IXnAqon/view?usp=drive_link', '_blank')
        } else if (no === 3) {
          window.open('https://drive.google.com/file/d/1fODpavgiv0CSRXwHt-WRm0OlM2rpNMdx/view?usp=drive_link', '_blank')
        }
      },
    },
  }
</script>
